@tailwind base;

@tailwind components;

@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.text-shadow {
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
}

/* This is a TEMPORARY (hopefully) hack to prevent ag-grid (v23) from rendering cels 2x
 See issue here for more info https://github.com/ag-grid/ag-grid/issues/3731 */
.ag-react-container > :not(:first-child) {
    display: none !important;
}

.ag-theme-minimal[class] {
    .ag-root-wrapper {
        border: none;
    }

    .ag-header {
        border: none;
    }

    .ag-row {
        border: none;
        border-width: 0;
        background-color: transparent;
    }

    .ag-row-hover {
        background-color: #f7fafc;
    }
}
